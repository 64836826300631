/// Importar React e o CSS necessário
import React from 'react';
import './styles.css'; // Certifique-se de que o caminho do CSS está correto
import foto1 from '../Images/foto1.jpg'
import foto2 from '../Images/foto2.jpg'

const SobrePesquisadora = () => {
  return (
    <div className="sobre-container fade-in">
      <h1 className="titulo-pagina">Sobre a Pesquisadora</h1>
      <div className="sobre-conteudo">
        {/* Coluna das Imagens */}
        <div className="foto-coluna">
          <div className="foto-quadrado">
            <img src={foto1} alt="Foto da Pesquisadora" className="foto" />
          </div>
        </div>
        
        {/* Coluna do Texto */}
        <div className="texto-coluna">
          <p>
            <strong>MEMORIAL</strong>
          </p>
          <blockquote>
            “E hoje eu caminhei a praia inteira
            <br />
            Com os pés na areia,
            <br />
            Coração em alto mar...”
            <br />
            <span>— Armandinho</span>
          </blockquote>
          <p>
            Sou a Julia Quezia Nunes Boina, tenho 23 anos e nasci em Matinhos, litoral do Paraná.
            Em 2010, minha mãe decidiu sair de Matinhos e voltar para sua cidade natal, Janiópolis. Hoje faz mais tempo que eu moro em Janiópolis do que eu morei em Matinhos. Mesmo fazendo muito tempo que estou em Janiópolis, chegar em Matinhos e sentir o cheiro do mar é como se eu estivesse em casa, assim como é quando sinto o cheiro da minha mãe.
          </p>
          <p>
            O mar traz lembranças que estão guardadas bem no fundo do meu coração, lembranças da infância, das brincadeiras, da minha família, das vezes em que minha vó, meus tios e meus primos iam nos visitar e nós acordávamos cedo para ir para a praia.
          </p>
          <p>
            Por muito tempo eu odiei morar em Janiópolis. Quando cheguei, não tinha amigos e não sentia que aqui fosse um lar, até que o tempo passou, fiz amigos, comecei a gostar da escola e acabei me acostumando. Hoje tenho muito mais memórias aqui do que em qualquer outro lugar.
          </p>
          <blockquote>
            “A leitura é uma fonte inesgotável de prazer,
            <br />
            mas, por incrível que pareça,
            <br />
            a quase totalidade não sente esta sede.”
            <br />
            <span>— Carlos Drummond de Andrade</span>
          </blockquote>
          <p>
            Eu me encontro em todos os livros que li. Não me lembro ao certo qual foi o primeiro e nem o porquê o li, mas acredito que essa relação tenha começado antes mesmo de eu saber ler, quando eu pintava os livros da Turma da Mônica, folheava gibis ou via alguém lendo. A paixão pela leitura é algo construído com o tempo. Em todos os momentos, um livro se fez presente em minha vida, trazendo uma festa inesperada ou uma carta de ninguém...
          </p>
          <p>
            Nos livros eu encontrava refúgio da vida, que não era muito fácil. Durante aquelas 100, 200 ou 300 páginas, eu me permitia esquecer a realidade, mas mesmo tentando fugir, eu sempre me encontrava em um parágrafo ou capítulo onde questões tão reais, tão atuais, estavam embrenhadas nas páginas de fantasias...
          </p>
          <blockquote>
            “Em nossas escolhas encontra-se o nosso destino.”
            <br />
            <span>— Toro e Funke, 2019, p.22</span>
          </blockquote>
          <p>
            Minha família é humilde e eu sempre tive consciência de que a única forma de melhorar as nossas condições seria estudando... e então... eu estudei...
          </p>
          <p>
            Em 2019, entrei na graduação em História na Unespar. No início, queria cursar Direito, mas não consegui. Digo que o curso de História me escolheu, pois com o passar do tempo me apaixonei e hoje não me vejo fazendo outra coisa.
          </p>
          <p>
            Durante o primeiro ano de graduação, eu me sentia um pouco perdida. No segundo ano, eu já gostava muito do curso, mas não havia encontrado algo que despertasse minha paixão. Até que, no terceiro ano, durante as aulas de ensino de história, fui apresentada ao conceito de decolonialidade e ao ensino de história pela professora Cyntia Simioni França. A partir desse momento, me encontrei e sabia que era sobre isso que eu queria pesquisar e ensinar.
          </p>
          <p>
            Por muito tempo, não entendi o motivo da existência do racismo. Não entendia por que a cor, o cabelo ou as características de uma pessoa incomodavam tanto. A cada situação de injustiça que eu via, sentia-me frustrada e sem saber o que fazer, até que entendi que esse preconceito foi construído. Para mudar, demanda tempo, mas mesmo que demore, farei o que puder para isso mudar, começando com esta pesquisa e com meus estudantes.
          </p>
          <blockquote>
            “Ninguém nasce odiando outra pessoa pela 
            cor de sua pele, por sua origem 
            ou ainda por sua religião. 
            Para odiar, as pessoas precisam aprender,
            e se podem aprender a odiar, 
            elas podem ser ensinadas a amar.”
            <br />
            <span>— Nelson Mandela</span>
          </blockquote>
        </div>
      </div>
    </div>
  );
};

export default SobrePesquisadora;
