import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa o useNavigate
import './styles.css';

function Gallery() {
  // Estado para controlar o título que está com brilho
  const [hoveredTitle, setHoveredTitle] = useState(null);

  // Hook de navegação
  const navigate = useNavigate();

  // Função para adicionar a animação no hover
  const handleMouseEnter = (index) => {
    setHoveredTitle(index);
  };

  // Função para remover a animação no mouse out
  const handleMouseLeave = () => {
    setHoveredTitle(null);
  };

  // Função para redirecionar para a página do lampejo
  const handleClick = (i) => {
    navigate(`/lampejo${i + 1}`);
  };

  return (
    <div className="gallery-container">
      {/* Título centralizado */}
      <h2 className="titulo-lampejos">Lampejos</h2>
      <div className="quadrados-container">
        {[...Array(6).keys()].map((i) => (
          <div
            key={i}
            className="quadrado"
            onMouseEnter={() => handleMouseEnter(i)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(i)} // Adiciona o evento de clique para redirecionamento
          >
            <h3 className={`quadrado-title ${hoveredTitle === i ? 'animar' : ''}`}>
              Lampejo {i + 1}
            </h3>
            <p className="quadrado-description">
              {i === 0
                ? 'Memórias'
                : i === 1
                ? 'Músicas'
                : i === 2
                ? 'Fragmentos literários'
                : i === 3
                ? 'Fragmentos Filmicos'
                : i === 4
                ? 'Notícias'
                : 'Obras de arte'}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
