import React from 'react';
import { FaInstagram } from 'react-icons/fa';
import LattesIcon from '../assets/lattes-new.svg'; // Caminho corrigido para o SVG local
import './styles.css';

function Footer() {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        {/* Seção à esquerda com o nome do site */}
        <div className="footer-left">
          <h2 className="footer-site-name">Histórias a Contrapelo - Por Julia Quezia Nunes Boina</h2> {/* Nome do site */}
        </div>

        {/* Seção de Contato no lado direito */}
        <div className="footer-contact">
          <h4>Contato</h4>
          <ul className="social-icons">
            {/* Link para o Currículo Lattes com o SVG local */}
            <li>
              <a href="http://lattes.cnpq.br/3329130688267891" target="_blank" rel="noopener noreferrer">
                <img src={LattesIcon} alt="Currículo Lattes" className="icon-lattes" />
              </a>
            </li>

            {/* Link para o Instagram com ícone padrão */}
            <li>
              <a href="https://instagram.com/juliaqueziaa" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={28} />
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Seção inferior do footer */}
      <div className="footer-bottom">
        <p>Desenvolvido por Bruno Simonetti de Souza © 2024</p>
      </div>
    </footer>
  );
}

export default Footer;
