import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

function Nav() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="hamburguer-menu" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? 'active' : ''}>
        <li><Link to="/">Início</Link></li>

        <li className="dropdown">
          <Link to="#" className="dropdown-toggle">Sobre</Link>
          <ul className="dropdown-menu">
            <li><Link to="/sobre-pesquisadora">A Pesquisadora</Link></li>
            <li><Link to="/prof-historia">Prof. Historia</Link></li>
            <li><Link to="/grupo-odisseia">Grupo Odisseia</Link></li>
            <li><Link to="/sobre-pesquisa">Pesquisa</Link></li>
          </ul>
        </li>

        <li className="dropdown">
          <Link to="#" className="dropdown-toggle">Downloads</Link>
          <ul className="dropdown-menu">
            <li><Link to="/oficinas">Lampejos</Link></li>
            <li><Link to="/producoes">Produção dos Estudantes</Link></li>
          </ul>
        </li>

        <li><Link to="#outraspro">Outras Produções</Link></li>
      </ul>
    </nav>
  );
}

export default Nav;
