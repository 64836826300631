import React from 'react';
import './styles.css';  // Certifique-se de que o CSS global está sendo importado

function LampejoLayout({ children }) {
  return (
    <div className="lampejo-container">
      {children}  {/* Renderizando o conteúdo dinâmico */}
    </div>
  );
}

export default LampejoLayout;
