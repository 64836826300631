// Importar React, o layout, e o CSS
import React from 'react';
import LampejoLayout from '../LampejoLayout'; // Certifique-se de que o caminho está correto


// Criando o componente Lampejo2
const Lampejo2 = () => {
  // Verificar se a largura da tela é menor que um certo valor (ex: 768px para dispositivos móveis)
  const isMobile = window.innerWidth <= 768;

  return (
    <LampejoLayout>
      <div className="lampejo-container">
        {/* Título centralizado */}
        <h1 className="lampejo-title lampejo-title-centered">2º Lampejo - Músicas</h1>
        
        {/* Texto justificado */}
        <p className="lampejo-text">
          No 2º Lampejo - <strong>Músicas</strong>, as alegorias foram escolhidas para que os estudantes refletissem sobre as problemáticas do racismo presentes nas músicas “Boa Esperança” do cantor Emicida e “Eu vim de lá” do cantor Kyan. Em forma de narrativa, os estudantes puderam pensar em suas realidades e como essas questões afetam suas vidas.
        </p>

        <blockquote className="lampejo-quote">
          Permita que eu fale, não as minhas cicatrizes
          Achar que essas mazelas me definem é o pior dos crimes
          É dar o troféu pro nosso algoz e fazer nós sumir  - Emicida
        </blockquote>

        <p className="lampejo-text">
          Através dessas músicas e das suas poderosas mensagens, os estudantes foram convidados a refletir sobre como o racismo continua a moldar suas experiências cotidianas, utilizando a narrativa como forma de explorar e transformar essa realidade.
        </p>

        <p className="lampejo-text">
          O processo criativo envolveu a imersão nas letras e histórias das músicas, incentivando os estudantes a se conectarem com suas próprias vivências e a perceberem as diversas formas de resistência e superação que fazem parte do contexto social.
        </p>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com o PDF da atividade */}
        <div className="activity-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Atividade Proposta</h2>
          <p className="lampejo-text">Aqui está o PDF contendo a atividade proposta aos estudantes:</p>
          {isMobile ? (
            <a
              href="./Oficinas/Oficina 2.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF da Atividade
            </a>
          ) : (
            <embed
              src="./Oficinas/Oficina 2.pdf" // Substitua pelo caminho correto do PDF da atividade
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com os trabalhos dos estudantes */}
        <div className="student-work-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Trabalhos dos Estudantes</h2>
          <p className="lampejo-text">Aqui está o PDF contendo todos os trabalhos produzidos pelos estudantes:</p>
          {isMobile ? (
            <a
              href="./producoes/producao2.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF dos Trabalhos
            </a>
          ) : (
            <embed
              src="./producoes/producao2.pdf" // Substitua pelo caminho correto do PDF dos trabalhos
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        <hr className="divider" />
      </div>
    </LampejoLayout>
  );
};

// Exportando o componente
export default Lampejo2;
