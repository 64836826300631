// Importar React, o layout, e o CSS
import React from 'react';
import LampejoLayout from '../LampejoLayout'; // Certifique-se de que o caminho está correto

// Criando o componente Lampejo6
const Lampejo6 = () => {
  // Verificar se a largura da tela é menor que um certo valor (ex: 768px para dispositivos móveis)
  const isMobile = window.innerWidth <= 768;

  return (
    <LampejoLayout>
      <div className="lampejo-container">
        {/* Título centralizado */}
        <h1 className="lampejo-title lampejo-title-centered">6º Lampejo - Obras de Arte</h1>
        
        {/* Texto justificado */}
        <p className="lampejo-text">
          No 6º Lampejo - <strong>Obras de Arte</strong>, decidimos dialogar com obras de artistas negros que utilizam a arte como meio de denúncia contra o racismo. Cada obra serviu como ponto de partida para reflexões profundas sobre a discriminação racial e o papel da arte como ferramenta de transformação social.
        </p>

        <blockquote className="lampejo-quote">
          {/* Citação (se houver) */}
        </blockquote>

        <p className="lampejo-text">
          Após refletirem sobre as obras, os estudantes foram incentivados a expressar suas próprias visões sobre o combate ao racismo, criando desenhos que representassem a luta pela igualdade e justiça racial.
        </p>

        <p className="lampejo-text">
          Este lampejo promoveu um diálogo profundo entre os estudantes e as obras de arte, mostrando como a criatividade pode ser uma poderosa ferramenta de resistência contra a opressão.
        </p>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com o PDF da atividade */}
        <div className="activity-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Atividade Proposta</h2>
          <p className="lampejo-text">Aqui está o PDF contendo a atividade proposta aos estudantes:</p>
          {isMobile ? (
            <a
              href="./Oficinas/Oficina 6.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF da Atividade
            </a>
          ) : (
            <embed
              src="./Oficinas/Oficina 6.pdf" // Substitua pelo caminho correto do PDF da atividade
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com os trabalhos dos estudantes */}
        <div className="student-work-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Trabalhos dos Estudantes</h2>
          <p className="lampejo-text">Aqui está o PDF contendo todos os trabalhos produzidos pelos estudantes:</p>
          {isMobile ? (
            <a
              href="./producoes/producao6.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF dos Trabalhos
            </a>
          ) : (
            <embed
              src="./producoes/producao6.pdf" // Substitua pelo caminho correto do PDF dos trabalhos
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        <hr className="divider" />
      </div>
    </LampejoLayout>
  );
};

// Exportando o componente
export default Lampejo6;
