import React from 'react';
import './styles.css';

function Oficinas() {
  return (
    <div className="oficinas-container fade-in">
      <h2 className="titulo-oficinas">Lampejos</h2>
      <p className="descricao-oficinas">Baixe os materiais didáticos em PDF para uso e referência:</p>
      <ul className="pdf-list">
        <li className="pdf-item">
          <a href="/Oficinas/Oficina 1.pdf" className="pdf-link" download>
            Lampejo 1 - Memórias
          </a>
        </li>
        <li className="pdf-item">
          <a href="/Oficinas/Oficina 2.pdf" className="pdf-link" download>
          Lampejo 2 - Músicas
          </a>
        </li>
        <li className="pdf-item">
          <a href="/Oficinas/Oficina 3.pdf" className="pdf-link" download>
          Lampejo 3 - Fragmentos Literários
          </a>
        </li>
        <li className="pdf-item">
          <a href="/Oficinas/Oficina 4.pdf" className="pdf-link" download>
          Lampejo 4 - Fragmentos Filmicos
          </a>
        </li>
        <li className="pdf-item">
          <a href="/Oficinas/Oficina 5.pdf" className="pdf-link" download>
          Lampejo 5 - Notícias
          </a>
        </li>
        <li className="pdf-item">
          <a href="/Oficinas/Oficina 6.pdf" className="pdf-link" download>
          Lampejo 6 - Obras de Arte
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Oficinas;
