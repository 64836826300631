import React from 'react';
import './styles.css';

function Producoes() {
  return (
    <div className="oficinas-container fade-in">
      <h2 className="titulo-oficinas">Produção dos Estudantes</h2>
      <p className="descricao-oficinas">Baixe as produções dos estudantes em PDF para uso e referência:</p>
      <ul className="pdf-list">
        <li className="pdf-item">
          <a href="/producoes/producao1.pdf" className="pdf-link" download>
            Lampejo 1 - Memórias
          </a>
        </li>
        <li className="pdf-item">
          <a href="/producoes/producao2.pdf" className="pdf-link" download>
           Lampejo 2 - Músicas
          </a>
        </li>
        <li className="pdf-item">
          <a href="/producoes/producao3.pdf" className="pdf-link" download>
          Lampejo 3 - Fragmentos Literários
          </a>
        </li>
        <li className="pdf-item">
          <a href="/producoes/producao4.pdf" className="pdf-link" download>
          Lampejo 4 - Fragmentos Fílmicos
          </a>
        </li>
        <li className="pdf-item">
          <a href="/producoes/producao5.pdf" className="pdf-link" download>
          Lampejo 5 - Notícias
          </a>
        </li>
        <li className="pdf-item">
          <a href="/producoes/producao6.pdf" className="pdf-link" download>
          Lampejo 6 - Obras de Arte
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Producoes;
