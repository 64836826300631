// Importar React, o layout, e o CSS
import React from 'react';
import LampejoLayout from '../LampejoLayout'; // Certifique-se de que o caminho está correto

// Criando o componente Lampejo5
const Lampejo5 = () => {
  // Verificar se a largura da tela é menor que um certo valor (ex: 768px para dispositivos móveis)
  const isMobile = window.innerWidth <= 768;

  return (
    <LampejoLayout>
      <div className="lampejo-container">
        {/* Título centralizado */}
        <h1 className="lampejo-title lampejo-title-centered">5º Lampejo - Notícias</h1>
        
        {/* Texto justificado */}
        <p className="lampejo-text">
          No 5º Lampejo - <strong>Notícias</strong>, analisamos fragmentos de jornais de 1887 e de notícias atuais, revelando como o racismo continua a ser uma realidade persistente na sociedade brasileira. Apesar dos 134 anos que separam essas publicações, a problemática racial segue sendo um "passado que não passa".
        </p>

        <blockquote className="lampejo-quote">
          {/* Citação (se houver) */}
        </blockquote>

        <p className="lampejo-text">
          Ao refletirem sobre como o racismo se perpetua ao longo do tempo, os estudantes foram estimulados a produzir suas próprias notícias, denunciando o racismo presente na sociedade brasileira e sugerindo formas de ação para o combate a essa injustiça.
        </p>

        <p className="lampejo-text">
          Este lampejo permitiu que os estudantes enxergassem a continuidade do racismo em diversas épocas, evidenciando a importância de um engajamento ativo na luta pela igualdade racial.
        </p>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com o PDF da atividade */}
        <div className="activity-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Atividade Proposta</h2>
          <p className="lampejo-text">Aqui está o PDF contendo a atividade proposta aos estudantes:</p>
          {isMobile ? (
            <a
              href="./Oficinas/Oficina 5.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF da Atividade
            </a>
          ) : (
            <embed
              src="./Oficinas/Oficina 5.pdf" // Substitua pelo caminho correto do PDF da atividade
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com os trabalhos dos estudantes */}
        <div className="student-work-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Trabalhos dos Estudantes</h2>
          <p className="lampejo-text">Aqui está o PDF contendo todos os trabalhos produzidos pelos estudantes:</p>
          {isMobile ? (
            <a
              href="./producoes/producao5.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF dos Trabalhos
            </a>
          ) : (
            <embed
              src="./producoes/producao5.pdf" // Substitua pelo caminho correto do PDF dos trabalhos
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        <hr className="divider" />
      </div>
    </LampejoLayout>
  );
};

// Exportando o componente
export default Lampejo5;
