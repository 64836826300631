// Importar React e o layout se necessário
import React from 'react';
import LampejoLayout from '../LampejoLayout'; // Certifique-se de que o caminho está correto

// Criando o componente Lampejo1
const Lampejo1 = () => {
  // Verificar se a largura da tela é menor que um certo valor (ex: 768px para dispositivos móveis)
  const isMobile = window.innerWidth <= 768;

  return (
    <LampejoLayout>
      <div className="lampejo-container">
        {/* Texto principal - agora centralizado */}
        <h1 className="lampejo-title lampejo-title-centered">1º Lampejo - Lampejo de Memórias</h1>
        <p className="lampejo-text">
          No 1º Lampejo - <strong>Lampejo de Memórias</strong>, utilizamos fragmentos de memórias da filósofa Djamila Ribeiro e da professora e historiadora Ilda Aparecida de Souza para refletirmos sobre as rememorações. 
          Em seguida, os estudantes produziram fragmentos de memória sobre momentos em que presenciaram ou viveram experiências de racismo.
        </p>
        <blockquote className="lampejo-quote">
        "É na relação com as diferentes temporalidades que as memórias são plenas de conhecimento e carregadas de sensibilidades".(FRANÇA,2015, p. 24)
        </blockquote>
        <p className="lampejo-text">
          Através dessas memórias, refletimos sobre como o racismo permeia não só o presente, mas também nossa história. O processo criativo levou os estudantes a mergulharem em suas próprias vivências, confrontando memórias difíceis, mas também partilhando relatos de superação e conscientização.
        </p>
        <p className="lampejo-text">
          Lampejos como esse nos lembram que olhar para o passado é uma forma essencial de compreender o presente e moldar um futuro mais justo e igualitário.
        </p>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com o PDF da atividade */}
        <div className="activity-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Atividade Proposta</h2>
          <p className="lampejo-text">Aqui está o PDF contendo a atividade proposta aos estudantes:</p>
          {isMobile ? (
            <a
              href="./Oficinas/Oficina 1.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF da Atividade
            </a>
          ) : (
            <embed
              src="./Oficinas/Oficina 1.pdf" // Substitua pelo caminho correto do PDF da atividade
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com os trabalhos dos estudantes */}
        <div className="student-work-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Trabalhos dos Estudantes</h2>
          <p className="lampejo-text">Aqui está o PDF contendo todos os trabalhos produzidos pelos estudantes:</p>
          {isMobile ? (
            <a
              href="./producoes/producao1.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF dos Trabalhos
            </a>
          ) : (
            <embed
              src="./producoes/producao1.pdf" // Substitua pelo caminho correto do PDF dos trabalhos
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        <hr className="divider" />
      </div>
    </LampejoLayout>
  );
};

// Exportando o componente
export default Lampejo1;
