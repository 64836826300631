import React from 'react';
import './styles.css';

function Header() {
  return (
    <header className="site-header">
      <div className="header-content">
        <div className="site-title">Histórias a Contrapelo</div>
        <div className="site-subtitle">Julia Quezia Nunes Boina</div>
      </div>
    </header>
  );
}

export default Header;
