// Importar React, o layout, e o CSS
import React from 'react';
import LampejoLayout from '../LampejoLayout'; // Certifique-se de que o caminho está correto


// Criando o componente Lampejo4
const Lampejo4 = () => {
  // Verificar se a largura da tela é menor que um certo valor (ex: 768px para dispositivos móveis)
  const isMobile = window.innerWidth <= 768;

  return (
    <LampejoLayout>
      <div className="lampejo-container">
        {/* Título centralizado */}
        <h1 className="lampejo-title lampejo-title-centered">4º Lampejo - Fragmentos Fílmicos</h1>
        
        {/* Texto justificado */}
        <p className="lampejo-text">
          No 4º Lampejo - <strong>Fragmentos Fílmicos</strong>, selecionamos cenas impactantes dos filmes <em>“Ó pai, ó”</em> e <em>“Medida Provisória”</em> para refletirmos sobre o racismo presente na sociedade brasileira. Essas obras cinematográficas oferecem uma poderosa crítica social, abordando questões de discriminação racial e as complexidades enfrentadas pela população negra no Brasil.
        </p>

        <blockquote className="lampejo-quote">
          {/* Citação (se houver) */}
        </blockquote>

        <p className="lampejo-text">
          Através dessas exibições, os estudantes foram incentivados a criar seus próprios manifestos contra o racismo no Brasil, expressando suas visões e sentimentos sobre a necessidade urgente de mudança e igualdade racial.
        </p>

        <p className="lampejo-text">
          Este lampejo não apenas estimulou discussões profundas sobre as realidades enfrentadas pela população negra, mas também empoderou os estudantes a usarem suas vozes para lutar contra as injustiças raciais.
        </p>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com o PDF da atividade */}
        <div className="activity-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Atividade Proposta</h2>
          <p className="lampejo-text">Aqui está o PDF contendo a atividade proposta aos estudantes:</p>
          {isMobile ? (
            <a
              href="./Oficinas/Oficina 4.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF da Atividade
            </a>
          ) : (
            <embed
              src="./Oficinas/Oficina 4.pdf" // Substitua pelo caminho correto do PDF da atividade
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com os trabalhos dos estudantes */}
        <div className="student-work-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Trabalhos dos Estudantes</h2>
          <p className="lampejo-text">Aqui está o PDF contendo todos os trabalhos produzidos pelos estudantes:</p>
          {isMobile ? (
            <a
              href="./producoes/producao4.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF dos Trabalhos
            </a>
          ) : (
            <embed
              src="./producoes/producao4.pdf" // Substitua pelo caminho correto do PDF dos trabalhos
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        <hr className="divider" />
      </div>
    </LampejoLayout>
  );
};

// Exportando o componente
export default Lampejo4;
