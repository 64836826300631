// Importando React
import React from 'react';
import './styles.css';

function SobrePesquisa() {
  return (
    <div className="sobrepesquisa-container fade-in">
      <h1 className="titulo-pagina">Sobre a Pesquisa</h1>
      <div className="texto-pesquisa">
        <p>
          Esta pesquisa tem como objetivo produzir conhecimentos históricos-educacionais a partir de práticas de rememorações, tendo como mote de reflexão o racismo no Brasil. Este trabalho foi construído com estudantes do 3º ano do ensino médio, em uma escola Estadual, na cidade de Boa Esperança-PR. Instigamos os estudantes a narrarem suas experiências e percepções sobre o racismo na sociedade brasileira.
        </p>
        <p>
          A metodologia foi ancorada pelo filósofo Walter Benjamin (1985) para trabalhar com práticas de rememoração por meio de alegorias como, memórias, músicas, literatura, fragmentos fílmicos, jornais e imagens para estimular as memórias dos estudantes na produção de narrativas escritas e iconográficas. Tais narrativas foram condensadas em mônadas, conceito entendido pelo aporte teórico-metodológico benjaminiano, que representam fragmentos de sentidos, com a potencialidade de se enxergar o todo.
        </p>
        <p>
          As rememorações estimulam as reflexões sobre injustiças e preconceitos enfrentados pelos afro-brasileiros em nosso país. Além disso, o trabalho envereda por uma abordagem decolonial, promovendo uma abordagem completa e sensível na educação, superando visões unilaterais e com diálogos mais inclusivos e plurais.
        </p>
      </div>
    </div>
  );
}

export default SobrePesquisa;
