// Importar React, o layout, e o CSS
import React from 'react';
import LampejoLayout from '../LampejoLayout'; // Certifique-se de que o caminho está correto

// Criando o componente Lampejo3
const Lampejo3 = () => {
  // Verificar se a largura da tela é menor que um certo valor (ex: 768px para dispositivos móveis)
  const isMobile = window.innerWidth <= 768;

  return (
    <LampejoLayout>
      <div className="lampejo-container">
        {/* Título centralizado */}
        <h1 className="lampejo-title lampejo-title-centered">3º Lampejo - Fragmentos Literários</h1>
        
        {/* Texto justificado */}
        <p className="lampejo-text">
          No 3º Lampejo - <strong>Fragmentos Literários</strong>, mobilizamos os fragmentos “13 de maio de 1958” e “16 de junho de 1958”, escritos por <strong>Carolina Maria de Jesus</strong> no livro <em>“Quarto de Despejo: Diário de uma Favelada”</em>. A leitura dessas passagens trouxe à tona importantes reflexões sobre a condição da população negra naquela época.
        </p>

        <blockquote className="lampejo-quote">
          {/* Citação (se houver) */}
        </blockquote>

        <p className="lampejo-text">
          Após a leitura, os estudantes foram convidados a escrever cartas endereçadas a Carolina Maria de Jesus, contando como está a população negra na atualidade, relacionando os contextos passados e presentes, e refletindo sobre os desafios e conquistas das últimas décadas.
        </p>

        <p className="lampejo-text">
          Esse lampejo permitiu aos estudantes perceber como as questões abordadas por Carolina ainda ressoam nos dias de hoje, e como a narrativa pode ser uma ferramenta poderosa de resistência e mudança.
        </p>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com o PDF da atividade */}
        <div className="activity-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Atividade Proposta</h2>
          <p className="lampejo-text">Aqui está o PDF contendo a atividade proposta aos estudantes:</p>
          {isMobile ? (
            <a
              href="./Oficinas/Oficina 3.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF da Atividade
            </a>
          ) : (
            <embed
              src="./Oficinas/Oficina 3.pdf" // Substitua pelo caminho correto do PDF da atividade
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        {/* Linha divisória */}
        <hr className="divider" />

        {/* Seção com os trabalhos dos estudantes */}
        <div className="student-work-section">
          <h2 className="lampejo-subtitle lampejo-title-centered">Trabalhos dos Estudantes</h2>
          <p className="lampejo-text">Aqui está o PDF contendo todos os trabalhos produzidos pelos estudantes:</p>
          {isMobile ? (
            <a
              href="./producoes/producao3.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="pdf-button"
            >
              Abrir PDF dos Trabalhos
            </a>
          ) : (
            <embed
              src="./producoes/producao3.pdf" // Substitua pelo caminho correto do PDF dos trabalhos
              type="application/pdf"
              width="100%"
              height="600px"
            />
          )}
        </div>

        <hr className="divider" />
      </div>
    </LampejoLayout>
  );
};

// Exportando o componente
export default Lampejo3;
